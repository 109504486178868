//Sidebar Imports
import{
    UilEstate,
    UilClipboardAlt,
    UilUsersAlt,
    UilPackage,
    UilChart,
    UilSignOutAlt,
    UilMoneyWithdrawal,
    UilUsdSquare,
} from "@iconscout/react-unicons";

import img1 from '../imgs/img1.png'
import img2 from '../imgs/img2.png'
import img3 from '../imgs/img3.png'
//Sidebar Data
export const SidebarData =[
    {
        icon: UilEstate,
        heading: "Dashboard",
    },
    {
        icon: UilClipboardAlt,
        heading: "Orders",
    },
    {
        icon: UilUsersAlt,
        heading: "Customers",
    },
    {
        icon: UilPackage,
        heading: 'Products',
    },
    {
        icon: UilChart,
        heading: "Analytics",
    },

];
export const CardsData =[
    {
        title:"Sales",
        color:{
            backGround:"linear-gradient(180deg,#bb67ff 0%,#c484f3 100%)",
            boxShadow: "0px 10px 20px 0px #e0c6f5"
        },
        barValue:70,
        value:"25,790",
        png:UilUsdSquare,
        series:[{
            name:"Sales",
            data:[31,40,28,51,42,109.100],
        },
    ],
    },
    {
        title:"Revenue",
        color:{
            backGround:"linear-gradient(180deg,#FF919D 0%,#FC929D 100%)",
            boxShadow: "0px 10px 20px 0px #FDC0C7"
        },
        barValue:80,
        value:"14,270",
        png:UilMoneyWithdrawal,
        series:[{
            name:"Revenue",
            data:[31,40,28,51,42,109.100],
        },
    ],
    },{
        title:"Expenses",
        color:{
            backGround:"linear-gradient(180deg,#F9D59B 0%,#F9D59B 100%)",
            boxShadow: "0px 10px 20px 0px #F9D59B"
        },
        barValue:60,
        value:"4,270",
        png:UilClipboardAlt,
        series:[{
            name:"Expenses",
            data:[31,40,28,51,42,109.100],
        },
    ],
    },
]

//Recent Update Card Data
export const UpdatesData =[
    {
        img:img1,
        name:"Andrew Thomas",
        noti:" has ordered Apple smart watch 2500mh battery",
        time:"25 Seconds ago"
    },
    {
        img:img2,
        name:"James Bond",
        noti:" has received samsung gadget for charging battery",
        time:"30 minutes ago"
    },
    
    {
        img:img3,
        name:"Angelina Petter",
        noti:" has received apple gear gadget",
        time:"1 hour ago"
    },

];